.sidebar-modal {
  --modal-width: 320px;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  width: var(--modal-width);
  height: calc(100% - var(--header-height));
  top: var(--header-height);
  left: 0;
  position: absolute;
  z-index: calc(var(--map-overlay-z-index) + 1);
  background-color: var(--color-bg-nav);
  color: var(--color-fg-base);
}

.sidebar-modal_menu-button {
  position: absolute;
  top: 0;
  right: 0;
}

.sidebar-modal_content {
  left: auto;
  right: 0;
  width: calc(100% - var(--modal-width));
  background-color: var(--color-bg-base);
}
