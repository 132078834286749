:root {
  --nav-header-height: 50px;
  --nav-open-width: 320px;
}

.navigation__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  min-height: calc(100vh - var(--header-height));
}

.navigation {
  background-color: var(--color-bg-nav);
  position: relative;
  min-width: 0;
  padding: 0;
  min-height: calc(100vh - var(--header-height));
  z-index: 100;
}

.navigation.navigation_open {
  min-width: var(--nav-open-width);
}

.navigation:not(.navigation_open) .navigation_menu-button {
  right: auto;
  left: 0;
  z-index: 1000;
}

.navigation:not(.navigation_open) .navigation_menu-button ~ * {
  display: none;
}

.navigation_hidden > * {
  display: none;
}

.navigation_hidden-button .navigation-header {
  display: none;
}

.navigation-scrollable {
  overflow-y: auto;
  margin-top: var(--nav-header-height);
  padding-bottom: 30px;
  padding-top: 30px;
  display: none;
}

.navigation-scrollable {
  height: calc(100vh - var(--header-height) - var(--nav-header-height));
}

.navigation-scrollable.navigation_open {
  display: block;
}

.navigation-header {
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-bg-nav);
}

.navigation-header.navigation_open {
  width: 100%;
  height: var(--nav-header-height);
}

.navigation__delimiter {
  width: 100%;
  height: 2px;
  margin-bottom: 30px;
  border-color: #6d6d6d;
}

.navigation__content {
  --content-height: calc(100vh - var(--header-height));
  --content-bottom-height: 0;
  height: var(--content-height);

  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  z-index: 99;
}

.navigation__content_bottom-bar {
  --content-bottom-height: var(--height-bottom-bar);
}

.page_fullscreen .navigation__content {
  overflow: hidden;
}
