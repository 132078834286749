.map-zoom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--color-fg-base);
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.map-zoom > .custom-checkbox {
  margin: 5px 0;
}
