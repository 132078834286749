.custom-select__wrap {
  position: relative;
  width: 100%;
}

.custom-select__error {
  color: var(--red-300);
  position: absolute;
  left: 10px;
  bottom: -13px;
  font-weight: 300;
  font-size: 12px;
}

/**
 * Main wrapper
 */
.custom-select {
  width: 100%;
  position: relative;
  --color-fg-option: var(--grey-700);
  --color-bg-option-active: var(--blue-500);
  --color-bg-option-hover: rgba(36, 193, 255, 0.5);
}

/**
* Value wrapper
*/
.custom-select__value {
  position: relative;
  z-index: 1;
}

.custom-select__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 19px;
  width: 0;
  height: 0;
}

.custom-select.has-focus .custom-select__value::after {
  transform: translateY(-50%) rotate(-180deg);
}

/**
* Input
*/
.custom-select__input {
  display: block;
  height: 32px;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px;
  font-size: 13px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1;
  background: var(--color-bg-input);
  color: var(--grey-700);
  border: none;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}

.custom-select__input::-webkit-search-decoration,
.custom-select__input::-webkit-search-cancel-button,
.custom-select__input::-webkit-search-results-button,
.custom-select__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.custom-select__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.custom-select__select {
  background-color: rgba(226, 226, 226, 1);
  box-shadow: 3px 5px 14px 2px rgba(0, 0, 0, 0.25);
}

/**
* Options
*/
.custom-select__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
* Option
*/
.custom-select__option {
  display: block;
  height: 20px;
  width: 100%;
  padding: 0 16px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 13px;
  text-align: left;
  cursor: pointer;
  color: var(--color-fg-option);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-select__option.is-selected {
  background: var(--color-bg-option-active);
}

.custom-select__option.is-highlighted,
.custom-select__option:not(.is-selected):hover {
  background: var(--color-bg-option-hover);
}

.custom-select__option.is-highlighted.is-selected,
.custom-select__option.is-selected:hover {
  background: var(--color-bg-option-hover);
}

.custom-select__option:disabled {
  opacity: 0.5;
  background: transparent !important;
}

/**
* States
*/
.custom-select.is-disabled {
  opacity: 0.5;
}

.custom-select.is-loading .custom-select__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.custom-select:not(.is-disabled) .custom-select__input {
  cursor: pointer;
}

/**
* Modifiers
*/

.custom-select:not(.is-loading) .custom-select__value::after {
  pointer-events: none;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #282a30 transparent transparent transparent;
}

.custom-select .custom-select__input:hover {
  border-color: var(--color-bg-option-active);
}

.custom-select .custom-select__select {
  position: absolute;
  z-index: 2;
  top: 32px;
  right: 0;
  left: 0;
  border-radius: 0;
  overflow: auto;
  max-height: 260px;
}
