.dndmodal {
  position: absolute;
  background: var(--color-bg-base);
  border-radius: 2px;
  box-shadow: var(--shadow-dnd-modal);
  z-index: var(--zind-dnd-modal);
  display: flex;
  flex-direction: column;
  min-width: 200px;
  min-height: 200px;
  max-width: 60vw;
  max-height: 90vh;
}

.dndmodal__heading {
  padding: 20px 30px;
  font-family: 'neo-sans', sans-serif;
  font-size: 15px;
  color: var(--color-fg-highlight);
  cursor: move;
  height: var(--modal-header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dndmodal__body {
  display: flex;
  height: calc(100% - var(--modal-header-height));
  overflow: auto;
}

.dndmodal__divider {
  position: relative;
  left: -30px;
  width: calc(100% + 40px);
  height: 0;
  border-bottom: 1px solid var(--color-bg-table-stripes);
  margin-top: 2px;
  margin-bottom: 12px;
}

.dndmodal__label {
  font-size: 13px;
  --label-color: var(--grey-200);
  display: block;
  border: 1px solid var(--label-color);
  color: var(--label-color);
  padding: 3px 5px;
  border-radius: 5px;
  box-shadow: none;
  cursor: default;
}

.dndmodal__label_active {
  --label-color: var(--blue-500);
  box-shadow: 0 0 3px var(--label-color);
}
