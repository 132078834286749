.checkbox__wrap {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.checkbox {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: white;
  cursor: auto;
}

.checkbox_radio {
  border-radius: 50%;
}

.checkbox__input:hover + .checkbox {
  background: var(--color-fg-button-hover);
}

.checkbox__input:focus + .checkbox {
  border: 1px solid black;
  box-shadow: 0 0 0 1px white;
  border-radius: 2px;
}

.checkbox__input:focus + .checkbox_radio {
  border-radius: 50%;
}

.checkbox__input:checked + .checkbox {
  background: var(--color-fg-button-hover);
}

.checkbox__input:disabled + .checkbox {
  color: var(--color-fg-nav);
  cursor: auto;
}

.checkbox__input:disabled + .checkbox {
  box-shadow: none;
  background: var(--color-fg-nav);
}

.checkbox__input:checked + .checkbox:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 6px;
  background: var(--color-bg-base);
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 var(--color-bg-base), 4px 0 0 var(--color-bg-base), 4px -2px 0 var(--color-bg-base),
    4px -4px 0 var(--color-bg-base), 4px -6px 0 var(--color-bg-base), 4px -8px 0 var(--color-bg-base);
  transform: rotate(45deg);
}

.checkbox__input:checked + .checkbox_radio:after {
  box-shadow: none;
  transform: none;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  top: 3px;
  left: 3px;
}
