:root {
  --news-ticker-delay: 3s;
  --news-ticker-duration: 10s;
  --news-ticker-width: 600px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ticker__wrapper {
  max-width: var(--news-ticker-width);
  overflow: hidden;
  position: relative;
}

@media (max-width: 1460px) {
  :root {
    --news-ticker-width: 400px;
  }
}

@media (max-width: 1280px) {
  :root {
    --news-ticker-width: 200px;
  }
}

.ticker__wrapper:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 30px;
  right: 0;
  top: 0;
  background: linear-gradient(90deg, rgba(46, 49, 59, 0.1), rgb(46, 49, 59));
}

.ticker {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 18px;
}

.ticker__content {
  display: flex;
  font-style: italic;
  color: inherit;
  font-size: inherit;
  line-height: 18px;
  cursor: pointer;
  opacity: 0;
}

.ticker__content--fade-in {
  animation: fade-in var(--news-ticker-duration) linear forwards;
}

.ticker__content--fade-out {
  animation: fade-out var(--news-ticker-duration) linear forwards;
}

.ticker__content--hidden {
  visibility: hidden;
}

.ticker__content:hover {
  animation-play-state: paused;
}

.ticker-animation-reset {
  animation: none !important;
}
