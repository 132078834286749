.button {
  height: 32px;
  line-height: 32px;
  font-size: 13px;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--color-bg-button);
  color: var(--color-fg-button);
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
}

.button:hover {
  background-color: var(--color-bg-button-hover);
}

.button_action {
  color: var(--color-text-button);
  background-color: var(--color-bg-button-action);
  --color-bg-button-hover: var(--color-bg-button-action-hover);
}

.button_accept {
  color: var(--color-fg-button-accept);
  background-color: var(--color-bg-button-accept);
  --color-bg-button-hover: var(--color-bg-button-accept-hover);
}

.button_revert {
  color: var(--color-text-button);
  --color-bg-button-hover: var(--color-bg-button-revert-hover);
  background-color: var(--color-bg-button-revert);
}

.button_disabled,
.button:disabled {
  cursor: not-allowed;
  color: var(--color-fg-button-disabled);
  background-color: var(--color-bg-button-disabled);
}
.button_disabled.button_loading,
.button_loading:disabled {
  cursor: wait;
}

.button_disabled {
  pointer-events: none;
}

.button_disabled:hover {
  background-color: var(--color-bg-button);
}

.button_small {
  min-width: 50px;
  height: 20px;
  line-height: 20px;
  width: fit-content;
}
